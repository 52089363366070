import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { FolderPlus, KindlyBlob } from 'frontend/assets/icons';
import { DIALOGUE_TYPES } from 'frontend/constants';
import { CreateTopic } from 'frontend/features/Library/modals';
import { getBuildUrl } from 'frontend/features/Library/utils';
import { useModal } from 'frontend/features/Modals';
import { useBotOrSkill } from 'frontend/hooks';

export function useDialogueFolderContextMenu() {
  const [showCreateTopic] = useModal(CreateTopic);
  const navigate = useNavigate();
  const { buildIdObject, buildType, buildId } = useBotOrSkill();

  const contextMenuId = 'library-context-menu';
  const newDialogueUrl = getBuildUrl({ buildType, buildId, dialogueType: DIALOGUE_TYPES.REGULAR, target: 'new' });

  const actions = useMemo(
    () => [
      { text: 'New dialogue', icon: KindlyBlob, onClick: () => navigate(newDialogueUrl) },
      { text: 'New folder', icon: FolderPlus, onClick: () => showCreateTopic({ buildIdObject }) },
    ],
    [buildIdObject, navigate, showCreateTopic, newDialogueUrl],
  );

  return { actions, contextMenuId };
}
