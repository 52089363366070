import { useMutation } from '@apollo/client';
import { useCallback, useMemo } from 'react';

import { CreateTopicDocument } from 'frontend/api/generated';
import { REFETCH_LIBRARY } from 'frontend/features/Library/utils/refetch';
import { useToast } from 'frontend/hooks';
import type { BuildIdObject } from 'frontend/propTypes/BuildIdObjectType';

import TopicForm from '../TopicForm';

const CreateTopic = ({
  hide,
  args: { parentTopicId, buildIdObject },
}: {
  hide: () => void;
  args: {
    parentTopicId: string;
    buildIdObject: BuildIdObject;
  };
}) => {
  const [create] = useMutation(CreateTopicDocument, REFETCH_LIBRARY);
  const toast = useToast();

  const initialValues = useMemo(() => ({ isActive: true }), []);

  const onSubmit = useCallback(
    async (topicInput) => {
      const result = await create({ variables: { ...buildIdObject, topicInput, parentTopicId } });
      const topic = result!.data!.createTopic!;

      toast.success(`Folder '${topic.name}' was created!'`);
    },
    [buildIdObject, create, parentTopicId, toast],
  );

  return <TopicForm onSubmit={onSubmit} initialValues={initialValues} actionName="New" hide={hide} />;
};

export default CreateTopic;
